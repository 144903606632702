import type {
  GetServerSidePropsContext,
  InferGetServerSidePropsType,
} from 'next'
import { NextSeo } from 'next-seo'
import { builder, Builder, BuilderComponent } from '@builder.io/react'
import { getAsyncProps } from '@builder.io/utils'
import * as Sentry from '@sentry/nextjs'

import { Layout } from '@/components'
import {
  getCategoryWithoutProducts,
  getCategoryWithProducts,
} from '@/features/categories/api/get-category'
import { StorefrontSettingsContext } from '@/stores'
import { getStorefrontSettings } from '@/utils/cms'
import { Category } from '@/generated/bigcommerce/graphql'

// TODO: Render this page with a react18 feature (either streaming SSR or server components to increase performance)
// https://nextjs.org/docs/advanced-features/react-18/overview
// https://nextjs.org/docs/advanced-features/react-18/streaming
function Home({
  storefrontSettings,
  content,
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  //if (!Builder.isEditing && !Builder.isPreviewing && !content) {
  // https://www.builder.io/c/docs/guides/preview-url

  //  return <div>404</div>
  //}

  return (
    <StorefrontSettingsContext.Provider value={storefrontSettings}>
      <Layout>
        <BuilderComponent
          content={content}
          model={'page'}
          options={{ includeRefs: true, enrich: true }}
        />
      </Layout>
      <NextSeo
        title={'ASSHOLES LIVE FOREVER'}
        canonical={'https://www.lindafinegold.com'}
      />
    </StorefrontSettingsContext.Provider>
  )
}

export async function getServerSideProps({
  req,
  res,
}: GetServerSidePropsContext) {
  res.setHeader(
    'Cache-Control',
    'public, s-maxage=60, stale-while-revalidate=119'
  )
  const content = await builder.get('page', { url: '/' }).promise()
  await getAsyncProps(content, {
    async CategoryProductTileSet({ categorySlug: slug, productCount }) {
      // todo: add above the fold meta prop to the very first tileset
      // https://github.com/BuilderIO/builder/tree/main/packages/utils#getasyncprops
      try {
        const { data } = await getCategoryWithProducts({ slug, productCount })
        return { data }
      } catch (e) {
        console.error(e)
        Sentry.captureException(e)
        return { data: {} }
      }
    },

    async CategoryList({ slugs }) {
      const categories = []

      for (const { slug } of slugs) {
        try {
          const { data } = await getCategoryWithoutProducts(slug)
          const category = data.site.route.node as Category
          categories.push({
            id: category.entityId,
            name: category.name,
            image: category.defaultImage,
            slug,
          })
        } catch (e) {
          console.error(e)
          Sentry.captureException(e)
        }
      }

      return {
        categories,
      }
    },
  })

  const storefrontSettings = await getStorefrontSettings()

  return {
    props: {
      storefrontSettings,
      content,
    },
  }
}

export default Home
